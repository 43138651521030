<div cdkMenuBar >
  <!-- #docregion file-trigger -->
  <button class="edudate-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="setup">Setup</button>
  <!-- #enddocregion file-trigger -->
  <button class="edudate-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="curriculum">Curriculum</button>
  <button class="edudate-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="instructor">Instructor</button>
  <button class="edudate-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="student">Student</button>

</div>

<ng-template #setup>
  <div class="edudate-menu" cdkMenu>
    <button class="edudate-menu-item" cdkMenuItem [cdkMenuTriggerFor]="lookups">
      Lookups <span>&#10148;</span>
    </button>
    <button class="edudate-menu-item" cdkMenuItem [cdkMenuTriggerFor]="contacts">
      Contacts <span>&#10148;</span>
    </button>
    <button class="edudate-menu-item" cdkMenuItem [cdkMenuTriggerFor]="iam">
      IAM <span>&#10148;</span>
    </button>
    <button class="edudate-menu-item" cdkMenuItem [cdkMenuTriggerFor]="academy">
      Academy <span>&#10148;</span>
    </button>

  </div>
</ng-template>
<ng-template #curriculum>
  <div class="edudate-menu" cdkMenu>
    <button class="edudate-menu-item" cdkMenuItem>
      <a mat-button class="edudate-menu-item-link" href="academy/academy-class/home" target="_blank">Classes</a>
    </button>

    <button class="edudate-menu-item" cdkMenuItem>
      <a mat-button class="edudate-menu-item-link" href="academy/course/home" target="_blank">Courses</a>
    </button>
    <button class="edudate-menu-item" cdkMenuItem>
      <a mat-button class="edudate-menu-item-link" href="academy/program/home" target="_blank">Programs</a>
    </button>
    <button class="edudate-menu-item" cdkMenuItem>
      <a mat-button class="edudate-menu-item-link" href="academy/persona/home" target="_blank">Persona?</a>
    </button>
    <hr />
  </div>
</ng-template>
<ng-template #instructor>
  <div class="edudate-menu" cdkMenu>
    <button class="edudate-menu-item" cdkMenuItem>
      <a mat-button class="edudate-menu-item-link" href="/academy/instructor/grade/home" target="_blank">Instructor Grading</a>
    </button>
    <button class="edudate-menu-item" cdkMenuItem>
      <a mat-button class="edudate-menu-item-link" href="/academy/instructor/qualification/home" target="_blank">Instructor Qualifications</a>
    </button>
    <button class="edudate-menu-item" cdkMenuItem>Instructor 1</button>
    <button class="edudate-menu-item" cdkMenuItem>Instructor 2</button>
    <hr />
    <button class="edudate-menu-item" cdkMenuItem>Instructor 3</button>
    <button class="edudate-menu-item" cdkMenuItem>Instructor 4</button>
    <button class="edudate-menu-item" cdkMenuItem>Instructor 5</button>
  </div>
</ng-template>

<ng-template #student >
  <div class="edudate-menu" cdkMenu>
    <button class="edudate-menu-item" cdkMenuItem>Student 1</button>
    <button class="edudate-menu-item" cdkMenuItem>Student 2</button>
    <button class="edudate-menu-item" cdkMenuItem>Student 3</button>
    <button class="edudate-menu-item" cdkMenuItem>Student 4</button>
  </div>
</ng-template>
<ng-template #profile >
  <div class="edudate-menu" cdkMenu>
    <button class="edudate-menu-item" cdkMenuItem>Login</button>
    <button class="edudate-menu-item" cdkMenuItem>My Profile</button>
    <button class="edudate-menu-item" cdkMenuItem>Logout</button>
  </div>
</ng-template>
<ng-template #lookups>

  <div class="edudate-menu" cdkMenu>
    <button class="edudate-menu-item" cdkMenuItem>
      <a mat-button class="edudate-menu-item-link" href="setup/lookup/message/home" target="_self">Messages</a>
    </button>
    <button class="edudate-menu-item" cdkMenuItem>
      <a mat-button class="edudate-menu-item-link" href="setup/lookup/dept/home" target="_blank">Departments</a>
    </button>
  </div>

</ng-template>
<ng-template #contacts>
  <div class="edudate-menu" cdkMenu>
    <button class="edudate-menu-item" cdkMenuItem>
      <a mat-button class="edudate-menu-item-link" href="setup/contact/contact-class/home" target="_blank">Contact Classes</a>
    </button>
    <button class="edudate-menu-item" cdkMenuItem>
      <a mat-button class="edudate-menu-item-link" href="setup/contact/contact-type/home" target="_blank">Contact Types</a>
    </button>
    <button class="edudate-menu-item" cdkMenuItem>
      <a mat-button class="edudate-menu-item-link" href="setup/contact/contact/home" target="_blank">Contacts</a>
    </button>
  </div>
</ng-template>
<ng-template #iam>
  <div class="edudate-menu" cdkMenu>
    <button class="edudate-menu-item" cdkMenuItem>
      <a mat-button class="edudate-menu-item-link" href="iam/sec-permission/home" target="_blank">Permissions</a>
    </button>
    <button class="edudate-menu-item" cdkMenuItem>
      <a mat-button class="edudate-menu-item-link" href="iam/sec-subject/home" target="_blank">Subjects</a>
    </button>
    <button class="edudate-menu-item" cdkMenuItem>
      <a mat-button class="edudate-menu-item-link" href="iam/sec-role/home" target="_blank">Roles</a>
    </button>
    <button class="edudate-menu-item" cdkMenuItem>
      <a mat-button class="edudate-menu-item-link" href="iam/profile/home" target="_blank">User Profiles</a>
    </button>
    <button class="edudate-menu-item" cdkMenuItem>
      <a mat-button class="edudate-menu-item-link" href="setup/lookup/persona-status/home" target="_blank">Persona Statuses</a>
    </button>
  </div>
</ng-template>
<ng-template #academy>
    <div class="edudate-menu" cdkMenu>
      <button class="edudate-menu-item" cdkMenuItem>
        <a mat-button class="edudate-menu-item-link" href="/academy/instructor/school/home" target="_blank">Schools</a>
      </button>
      <button class="edudate-menu-item" cdkMenuItem>
        <a mat-button class="edudate-menu-item-link" href="academy/venue/home" target="_blank">Venues</a>
      </button>
       <hr/>
      <button class="edudate-menu-item" cdkMenuItem>
        <a mat-button class="edudate-menu-item-link" href="academy/course-level/home" target="_blank">Course Levels</a>
      </button>
      <button class="edudate-menu-item" cdkMenuItem>
        <a mat-button class="edudate-menu-item-link" href="academy/class-category/home" target="_blank">Class Categories</a>
      </button>
    </div>
</ng-template>


