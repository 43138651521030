import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
/*
Super class for all interceptors
*/
@Injectable()
export abstract class EdudateInterceptor implements HttpInterceptor {

  constructor() {}
  protected processResponse(service:string,response: HttpResponse<any>) {
    // console.log(service + ' interceptor-response');
    return response;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request);
  }
}
