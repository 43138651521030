import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'edudate-pnf',
  templateUrl: './edudate-pnf.component.html',
  styleUrls: ['./edudate-pnf.component.scss'],
  standalone: true,
  imports: []
})
export class EdudatePNFComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
