import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'edudate-footer',
  templateUrl: './edudate-footer.block.html',
  styleUrls: ['./edudate-footer.block.scss'],
  standalone: true
})
export class EdudateFooterBlock implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
