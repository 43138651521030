import { Component } from '@angular/core';
import { CdkMenu, CdkMenuBar, CdkMenuGroup, CdkMenuItem, CdkMenuItemCheckbox, CdkMenuItemRadio, CdkMenuTrigger } from '@angular/cdk/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
  selector: 'edudate-menu',
  standalone: true,
  imports:[
    CdkMenuBar,
    CdkMenuItem,
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuGroup,
    CdkMenuItemCheckbox,
    CdkMenuItemRadio
  ],
  templateUrl: './edudate-menu.block.html',
  styleUrl: './edudate-menu.block.scss'
})
export class EdudateMenuBlock {}
