<div cdkMenuBar>

      <button class="edudate-menu-bar-item" cdkMenuItem [cdkMenuTriggerFor]="profile" >
        <img src="{{user_picture_url}}" class="edudate-avatar-profile" alt="Avatar">
      </button>

</div>

<ng-template #profile>
    <div class="edudate-menu" cdkMenu>
        <button class="edudate-menu-item" cdkMenuItem>
            <a mat-button class="edudate-menu-item-link" href="iam/authn/user-login/home" target="_self">Login</a>
        </button>

        <button class="edudate-menu-item" cdkMenuItem>
            My Profile
        </button>
        <button class="edudate-menu-item" cdkMenuItem (click)="logout()">
            Logout
        </button>
        <button class="edudate-menu-item" cdkMenuItem (click)="onLoginTest()">
            Login Test
        </button>
        <button class="edudate-menu-item" cdkMenuItem (click)="onLoginTest()">
            <a mat-button class="edudate-menu-item-link" href="iam/authorized" target="_self">Test Authorization</a>
        </button>
    </div>
</ng-template>