
import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { edudateViewRoutes } from './edudateView.routes';


import { SETTINGS as AUTH_SETTINGS, USE_DEVICE_LANGUAGE, PERSISTENCE } from '@angular/fire/compat/auth';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideAnalytics, getAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { providePerformance, getPerformance } from '@angular/fire/performance';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { provideRemoteConfig, getRemoteConfig } from '@angular/fire/remote-config';
import { provideVertexAI, getVertexAI } from '@angular/fire/vertexai-preview';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment } from 'src/environments/environment';
import { HAHFoundationModule } from './hah-foundation/hah-foundation.module';
import { HttpSaniInterceptor } from './hah-foundation/services/http-sani/http-sani.interceptor';
import { AuthJWTInterceptor } from './iam/services/auth-jwt/auth-jwt.interceptor';

import { AlertService } from './services/alert/alert.service';


export const edudateViewConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }), 
    provideRouter(edudateViewRoutes),
    importProvidersFrom(
      AngularFireModule.initializeApp(environment.firebaseConfig), 
      AngularFirestoreModule, 
      FontAwesomeModule, 
      HAHFoundationModule
    ),
    { provide: AUTH_SETTINGS, useValue: { appVerificationDisabledForTesting: true } },
    { provide: USE_DEVICE_LANGUAGE, useValue: true },
    { provide: PERSISTENCE, useValue: 'session' },
    // Register Interceptors - follow order
    { provide: HTTP_INTERCEPTORS, useClass: HttpSaniInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthJWTInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    ScreenTrackingService,
    UserTrackingService,
    provideFirestore(() => getFirestore()),
    provideDatabase(() => getDatabase()),
    provideFunctions(() => getFunctions()),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    provideStorage(() => getStorage()),
    provideRemoteConfig(() => getRemoteConfig()),
    provideVertexAI(() => getVertexAI()),
    provideAnimations(),
    AlertService
  ]
};
