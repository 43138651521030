import { CdkMenuBar, CdkMenuItem, CdkMenuTrigger, CdkMenu, CdkMenuGroup } from '@angular/cdk/menu';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/iam/models/user.model';
import { AuthService } from 'src/app/iam/services/auth/auth.service';
import firebase from 'firebase/compat/app';
import { AlertService } from 'src/app/services/alert/alert.service';
/*
TO DO Test cases:
1. Test Component created
2. Test Login - picture changed
3. Test TestLogin - DB and error messages
4. Test Google Login
5. Test Facebook Login
6. Test Twitter Login

*/
@Component({
  selector: 'edudate-profile-menu',
  standalone: true,
  imports: [
    CdkMenuBar,
    CdkMenuItem,
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuGroup,
  ],
  templateUrl: './edudate-profile-menu.block.html',
  styleUrl: './edudate-profile-menu.block.scss'
})
export class EdudateProfileMenuBlock implements OnInit {
  avatar_gender: string[] = ["male", "female"];
  avatar_random: number = Math.floor(Math.random() * 10) % 2;
  error_message: string = "";

  user_picture_url: string = `assets/img/img_avatar-${this.avatar_gender[this.avatar_random]}.png`;
  constructor(public authService: AuthService,
              private alert: AlertService
  ) { }
  ngOnInit(): void {
    this.authService.user$
      .subscribe({
        next: (user) => {
          this.user_picture_url = user?.photoURL || this.user_picture_url;
        }
        ,
        error: (err) => console.log("Not Logged"),
        complete: () => console.log("onInit() finally -> user$")
      })
  }
  onLoginTest() {
    console.log("Automatic Login")
    let user: User = new User();
    user.name = "test";
    user.email = "tim.hass@edudate.academy";
    user.password = "123456";

    this.authService.login(user)
      .then((userCredentials: firebase.auth.UserCredential | void) => {
        if (userCredentials instanceof Object) {
          let user = userCredentials.user;
          let user_name = user?.displayName || "No Name";
          this.alert.info("Yaay, user logged in with name : "+ user_name );
        }
      },
        (err) => {
          // Check return status should be alerted.
          this.error_message = `The user cannot be verified. ${err.message}`;
        });
  }
  logout(){
    this.authService.logout()
    .then(() => {
      this.alert.info("User logged out" );
      this.user_picture_url =  this.user_picture_url;
    })
    .catch((error) => {
      this.alert.error("Logout error:", error);
    });
  }
}
