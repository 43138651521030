import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, Input , inject} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Alert, AlertType } from 'src/app/models/aler.model';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'edudate-alert',
  templateUrl: './edudate-alert.block.html',
  styleUrls: ['./edudate-alert.block.scss'],
  standalone: true,
  imports:[
    CommonModule,MatGridListModule,MatButtonModule,MatIconModule
  ]
})
export class EdudateAlertBlock implements OnInit, OnDestroy {
  @Input() id = 'edudate-alert';
  @Input() fade = true;
  alerts: Alert[] = [];
  alertSubscription?: Subscription;
  routeSubscription?: Subscription;
  constructor(
    private router: Router,
    private alert: AlertService,
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {
    this.alertSubscription = new Subscription(undefined);
    this.routeSubscription = new Subscription(undefined);


    this.iconRegistry.addSvgIcon('close', 
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/cancel_16dp_5F6368_FILL0_wght400_GRAD0_opsz20.svg'));
  }
  ngOnDestroy(): void {
    // unsubscribe to avoid memory leaks
    this.alertSubscription!.unsubscribe();
    this.routeSubscription!.unsubscribe();
  }

  ngOnInit(): void {
    // subscribe to new alert notifications
    this.alertSubscription = this.alert.onAlert(this.id)
     .subscribe(alert => {
        // clear alerts when an empty alert is received
        if (!alert.message) {
          // filter out alerts without 'keepAlive' flag
          this.alerts = this.alerts.filter(x => x.keepAlive)

          // remove 'keepAlive' flag on the rest
          this.alerts.forEach(x => delete x.keepAlive)
          return
        }

        // add alert to array
        this.alerts.push(alert)

        // auto close alert if required
        if (alert.autoClose) {
          setTimeout(() => this.removeAlert(alert), 2500)
        }
      });

    // clear alerts on location change
    this.routeSubscription = this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          this.alert.clear(this.id);
        }
      });

  }
  removeAlert(alert: Alert) {
    // check if already removed to prevent error on auto close
    if (!this.alerts.includes(alert)) return;

    if (this.fade) {
      // fade out alert
      alert.fade = true;

      // remove alert after faded out
      setTimeout(() => {
        this.alerts = this.alerts.filter(x => x !== alert);
      }, 350);
    } else {
      // remove alert
      this.alerts = this.alerts.filter(x => x !== alert);
    }
  }

  cssClass(alert: Alert) {

    if (!alert) return;

    const classes = ['alert', 'alert-dismissible'];

    const alertTypeClass = {
      [AlertType.Success]: 'alert-success',
      [AlertType.Error]: 'alert-danger',
      [AlertType.Info]: 'alert-info',
      [AlertType.Warning]: 'alert-warning',
      [AlertType.Primary]: 'alert-primary',
      [AlertType.Secondary]: 'alert-secondary',
      [AlertType.Light]: 'alert-light',
      [AlertType.Dark]: 'alert-dark',
      [AlertType.Black]: 'alert-black',
      [AlertType.White]: 'alert-white'
    }

    classes.push(alertTypeClass[alert.type]);

    if (alert.fade) {
      classes.push('alert-fade-out');
    }
    return classes.join(' ');
  }

}
