import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { EdudateInterceptor } from 'src/app/services/edudate/edudate.interceptor';
import { AuthService } from '../auth/auth.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { tap, catchError, take, switchMap } from 'rxjs/operators';


@Injectable()
export class AuthJWTInterceptor extends EdudateInterceptor {
  
  constructor(private authService: AuthService,
    private alert: AlertService,
    public auth: AngularFireAuth) { super(); }

  jwt(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    //  return next.handle(request);
    //  console.log('jwt interceptor-request');
    //   //Method 1
    return this.auth.idToken
      .pipe(
        take(1),
        switchMap((token: any) => {
          if (token) request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } })
          return next.handle(request)
            .pipe(  //catch the auth errors on the way back
              tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                  this.processResponse("jwt",event);
                }
              }),
              catchError((err: HttpErrorResponse) => {
                let errorMsg: string = err.error.message;
                if ([401, 403].includes(err.status))
                  this.alert.error("User unauthorized to access the resource. " + err.error.message);
                else
                  errorMsg = "Intercept server side :" + err.error.message;

                errorMsg = err.error.error;
                return throwError(() => {
                  return new Error(errorMsg);
                });
              }
              )
            )
        }
        )
      );

  }

  override intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.jwt(request, next);
    // return next.handle(request);
  }



}
