<mat-grid-list cols="16" rowHeight="40px" [gutterSize]="'0px'">
  <mat-grid-tile [colspan]="1" [rowspan]="2">
    <div class="edudate-logo">
      <a href="dashboard">
        <img src="assets/brand/edudate-academy-logo-title.png" class="edudate-logo-image" align="left">
      </a>
    </div>
  </mat-grid-tile>

  <mat-grid-tile [colspan]="13" [rowspan]="1">
    <div class="edudate-menu">
      <edudate-menu></edudate-menu>
    </div>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="2" [rowspan]="1">
    <div class="edudate-menu-profile">
      <edudate-profile-menu></edudate-profile-menu>
    </div>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="15" [rowspan]="alerts_count">
    <div class="alert-box" >
    <!-- to get width/height to work they myst provided in style= , class doesn't work -->
    <!-- <div style="width:100%;height:100%"> -->
      <edudate-alert></edudate-alert>
    </div>
  </mat-grid-tile>
</mat-grid-list>