import { Component, OnInit } from '@angular/core';
import { EdudateMenuBlock } from '../edudate-menu/edudate-menu.block';
import { EdudateAlertBlock } from '../edudate-alert/edudate-alert.block';
import { MatGridListModule } from '@angular/material/grid-list';
import { EdudateProfileMenuBlock } from '../edudate-profile-menu/edudate-profile-menu.block';
import { EdudateTestBlock } from '../edudate-test/edudate-test.block';

@Component({
  selector: 'edudate-header',
  templateUrl: './edudate-header.block.html',
  styleUrls: ['./edudate-header.block.scss'],
  standalone: true,
  imports:[
    EdudateMenuBlock,
    EdudateProfileMenuBlock,
    EdudateAlertBlock,
    EdudateTestBlock,
    MatGridListModule]
})

export class EdudateHeaderBlock implements OnInit {
  alerts_count=1; //Calculate from the service
  constructor() { }

  ngOnInit(): void {
  }

}
