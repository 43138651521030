/// <reference types="@angular/localize" />



import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';

import { EdudateLayoutComponent } from './app/components/edudate-layout/edudate-layout.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { edudateViewConfig } from './app/edudateView.Config';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(EdudateLayoutComponent, edudateViewConfig)
  .catch(err => console.error(err));
