import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterModule, RouterOutlet } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EdudateFooterBlock } from 'src/app/blocks/edudate-footer/edudate-footer.block';
import { EdudateHeaderBlock } from 'src/app/blocks/edudate-header/edudate-header.block';
import { edudateViewRoutes } from 'src/app/edudateView.routes';

import { HAHFoundationModule } from 'src/app/hah-foundation/hah-foundation.module';


@Component({
  selector: 'edudate-layout',
  templateUrl: './edudate-layout.component.html',
  styleUrls: ['./edudate-layout.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    EdudateHeaderBlock,
    EdudateFooterBlock,
    CommonModule,
    AngularFirestoreModule,
    FontAwesomeModule,
    HAHFoundationModule,
  ]
})
export class EdudateLayoutComponent implements OnInit {
  title = 'Edudate Academy';
  isLoading: boolean = true;
  constructor(private router: Router) {
    this.router.events.subscribe(
      (routerEvent: Event) => {
        if (routerEvent instanceof NavigationStart) {
          this.isLoading = true;
        }
        if (routerEvent instanceof NavigationEnd ||
          routerEvent instanceof NavigationCancel ||
          routerEvent instanceof NavigationError) {
          this.isLoading = false;
        }
      }
    )
  }

  ngOnInit(): void {
  }

}
