import { Component } from '@angular/core';

@Component({
  selector: 'edudate-test',
  standalone: true,
  imports: [],
  templateUrl: './edudate-test.block.html',
  styleUrl: './edudate-test.block.sass'
})
export class EdudateTestBlock {

}
