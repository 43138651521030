import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import {  Observable, pipe, tap} from 'rxjs';
import { EdudateInterceptor } from 'src/app/services/edudate/edudate.interceptor';


@Injectable()
export class HttpSaniInterceptor extends EdudateInterceptor {

  constructor() { super(); }

  override intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // console.log('http sani-request');
    return next.handle(request)
    .pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // this.processResponse("sani",event);
        }
      })
    )
  }
}
