import { Routes } from '@angular/router';
import { EdudatePNFComponent } from './components/edudate-pnf/edudate-pnf.component';
const dashboardModule = () => import('./welcome/dashboard/dashboard.module').then(x => x.DashboardModule);
const iamModule = () => import('./iam/iam.module').then(x => x.IAMModule);
const setupModule = () => import('./setup/setup.module').then(x => x.SetupModule);
const academyModule = () => import('./academy/academy.module').then(x => x.AcademyModule);
const instructorModule = () => import('./welcome/instructor-portal/instructor-portal.module').then(x => x.InstructorPortalModule);
const studentModule = () => import('./welcome/student-portal/student-portal.module').then(x => x.StudentPortalModule);
const administratorModule = () => import('./welcome/administrator/administrator.module').then(x => x.AdministratorModule);

export const edudateViewRoutes: Routes = [
    { path: '',   redirectTo: '/dashboard', pathMatch: 'full' },
    { path: 'dashboard', loadChildren: dashboardModule}, 
    { path: 'iam', loadChildren: iamModule}, 
    { path: 'setup', loadChildren: setupModule}, 
    { path: 'academy', loadChildren: academyModule}, 
    { path: 'instructor', loadChildren: instructorModule}, 
    { path: 'student', loadChildren: studentModule}, 
    { path: 'administrator', loadChildren: administratorModule}, 
    { path: 'PNF', component: EdudatePNFComponent},
    { path: '**',   redirectTo: '/PNF', pathMatch: 'full' },
  
  ];;